/* eslint-disable no-param-reassign */
import React, { useEffect, useState } from 'react';
import css from 'styled-jsx/css';
import PropTypes from 'prop-types';
import CardBase from '@lmig/dotcom-aspect-components/Utility/CardBase';
import { Accordion, AccordionTab, IconClock } from '@lmig/lmds-react';
import Heading from '@lmig/dotcom-aspect-components/Utility/Heading';
import Dot from './Dot';

const { className: headingClass, styles: headingStyles } = css.resolve`
  .lm-Heading {
    padding: 0 0 0 1rem;
    margin: 0;
  }
`;

const { className: cardBaseClass, styles: cardBaseStyles } = css.resolve`
  :global(.hoursInfo) {
    height: 100%;
  }
  :global(.hoursLocationInfo .lmig-Accordion-list-listItem) {
    padding: 1.25rem 1.25rem 0 1.25rem;
  }
  :global(.hoursLocationInfo .lmig-Accordion--hasIcon .lmig-Accordion-list-listItem-header-button) {
    padding: 0 0 1.25rem 0;
  }
  :global(.hoursLocationInfo .lmig-Accordion-content) {
    padding: 0;
  }
  :global(.hoursLocationInfo .lmig-Accordion-list-listItem:last-child) {
    border-bottom-color: transparent;
  }
`;

const { className: accordionClass, styles: accordionStyles } = css.resolve`
  @import './global-styles/tokens';
  :global(.info .lmig-Accordion-list-listItem-header-caret) {
    @media screen and (min-width: $lm-breakpoint-md-min) {
      display: none;
    }
  }
`;

const convertTimeTo24Hours = time => {
  const [hours, minutes] = time.split(":");
  let parsedHours = parseInt(hours, 10);
  const parsedMinutes = parseInt(minutes.slice(0, -2), 10);

  if (time.includes("pm") && parsedHours !== 12) {
    parsedHours += 12;
  } else if (time.includes("am") && parsedHours === 12) {
    parsedHours = 0;
  }

  return `${parsedHours.toString().padStart(2, "0")}:${parsedMinutes.toString().padStart(2, "0")}`;
};

const DivOrHeader = ({ isDiv, className, children }) => isDiv
  ? <div className={className}>{children}</div>
  : <>
    <h3 className={className}>{children}</h3>
    <style jsx>{`
  .hoursCardInnerWrapper {
    font-weight: 400;
    margin-bottom: 1rem;
    font-size: 1rem;
  }
`}</style>
  </>;

const Hours = ({
  dailyHours,
  hasSchema = true,
  type = 'both'
}) => {
  const [isEvaluated, setIsEvaluated] = useState(false);
  const [sameTimeWeekday, setSameTimeWeekday] = useState(false);
  const [eveningsByAppointment, setEveningsByAppointment] = useState(false);
  const hasHours = Object.keys(dailyHours).length;
  const nonWeekdays = ['sunday', 'saturday'];
  const weekdays = Object.keys(dailyHours).filter(day => !nonWeekdays.includes(day)).reduce((obj, key) => {
    obj[key] = dailyHours[key];

    return obj;
  }, {});
  const weekends = Object.keys(dailyHours).filter(day => nonWeekdays.includes(day)).reduce((obj, key) => {
    obj[key] = dailyHours[key];

    return obj;
  }, {});

  useEffect(() => {
    const allWeekdaysTheSame = [];

    Object.keys(weekdays).forEach((day) => {
      const dayToCompare = weekdays[day];
      const results = Object.keys(weekdays).map((day2) => {
        const dayObj = weekdays[day2];

        return JSON.stringify(dayObj) === JSON.stringify(dayToCompare);
      });

      if (results.every(val => val === results[0])) {
        allWeekdaysTheSame.push(true);
      }
    });

    if (allWeekdaysTheSame.length === 5 && allWeekdaysTheSame.every(val => val === allWeekdaysTheSame[0])) {
      setSameTimeWeekday(true);
    }

    setIsEvaluated(true);
  }, [weekdays]);

  useEffect(() => {
    Object.keys(dailyHours).forEach((day) => {
      if (dailyHours[day]?.eveningsByAppointment) {
        setEveningsByAppointment(true);
      }
    });
  }, [dailyHours]);

  const renderHoursInfo = (options = {}) => {
    const { useSchema = true } = options;

    return (
      <DivOrHeader className="hoursCardInnerWrapper" isDiv={!useSchema}>
        {useSchema && Object.entries(dailyHours).map(([key, value]) => {
          if (value?.start && value?.end) {
            return <meta key={`meta-${key}`} itemProp="openingHours" content={`${key.charAt(0).toUpperCase() + key.slice(1, 2)} ${convertTimeTo24Hours(value.start)}-${convertTimeTo24Hours(value.end)}`} />;
          }

          return null;
        })}
        {sameTimeWeekday ? <>
          <div className='dayAndTimeWrapper'>
            <div className='dayName'>Monday-Friday</div>
            <div className='timeWithDot'>
              {weekdays.monday?.isClosed ? <div className='timeRange'>Closed</div> : <div className='timeRange'>
                {weekdays.monday?.byAppointmentOnly ? 'By appointment only' : `${weekdays.monday?.start} - ${weekdays.monday?.end}`}
              </div>}
              {weekdays.monday?.eveningsByAppointment && <Dot number={1} inlineMargin="-0.2rem 0 0  0.25rem" />}
            </div>
          </div>
          <hr className='hoursHr' />
        </> : <>
          {Object.keys(weekdays).map((day) => {
            const properDay = day.charAt(0).toUpperCase() + day.slice(1);

            return (
              <>
                <div className='dayAndTimeWrapper' key={day}>
                  <div className='dayName'>{properDay}</div>
                  <div className='timeWithDot'>
                    {weekdays[day]?.isClosed ? <div className='timeRange'>Closed</div> : <div className='timeRange'>
                      {weekdays[day]?.byAppointmentOnly ? 'By appointment only' : `${weekdays[day]?.start} - ${weekdays[day]?.end}`}
                    </div>}
                    {weekdays[day]?.eveningsByAppointment && <Dot number={1} inlineMargin="-0.2rem 0 0  0.25rem" />}
                  </div>
                </div>
                <hr className='hoursHr' />
              </>
            );
          })}
        </>}
        {Object.keys(weekends).map((day) => {
          const properDay = day.charAt(0).toUpperCase() + day.slice(1);

          return (
            <>
              <div className='dayAndTimeWrapper' key={day}>
                <div className='dayName'>{properDay}</div>
                <div className='timeWithDot'>
                  {weekends[day]?.isClosed ? <div className='timeRange'>Closed</div> : <div className='timeRange'>
                    {weekends[day]?.byAppointmentOnly ? 'By appointment only' : `${weekends[day]?.start} - ${weekends[day]?.end}`}
                  </div>}
                  {weekends[day]?.eveningsByAppointment && <Dot number={1} inlineMargin="-0.2rem 0 0  0.25rem" />}
                </div>
              </div>
              {day === "saturday" && <hr className='hoursHr' />}
            </>
          );
        })}
        {eveningsByAppointment && <div className='eveningsText'><Dot number={1} inlineMargin="-0.2rem 0.25rem 0 0" /><div>Evenings by appointment only</div></div>}
        <style jsx>{`
        .dayAndTimeWrapper {
          display: flex;
          justify-content: space-between;
        }
        .dayName {
          font-weight: bold;
        }
        .hoursHr{
          border: none;
          height: 1px;
          background-color: #e4e4e4;
        }
        .timeWithDot{
          display: flex;
          text-align: right;
        }
        .eveningsText{
          display: flex;
          margin-top: 1rem;
        }
    `}</style>
      </DivOrHeader>
    );
  };

  const { className: iconClass, styles: iconStyles } = css.resolve`
    @import './global-styles/tokens';
    .lm-Icon {
      min-height: 1.6rem;
      min-width: 1.6rem;
      @media screen and (min-width: $lm-breakpoint-md-min) {
        min-height: 2rem;
        min-width: 2rem;
      }
    }
  `;

  return (
    <CardBase className={`hoursInfo ${cardBaseClass} ${hasHours ? 'hasHours' : 'noHours'}`} shadow>
      {['both', 'mobile'].includes(type) &&
        <div className="hoursWrapperMobile">
          <Accordion as="div" noBorderTop>
            <AccordionTab icon={<IconClock className={iconClass} />} labelVisual="Hours" active className={accordionClass} >
              {isEvaluated && dailyHours && renderHoursInfo({ useSchema: false })}
            </AccordionTab>
          </Accordion>
        </div>
      }
      {['both', 'desktop'].includes(type) &&
        <div className="hoursWrapper">
          <div className="hoursCtaIcon">
            <IconClock className={iconClass} />
            <Heading type="h6" tag={hasSchema ? "h2" : "div"} className={headingClass}>Hours</Heading>
          </div>
          {isEvaluated && dailyHours && renderHoursInfo({ useSchema: hasSchema })}
        </div>
      }
      <style jsx>{`
        .hoursWrapper {
          padding: 1rem;
          display: none;
          min-height: 8rem;

          .hasHours & {
            min-height: 13.625rem;
          }

          @media screen and (min-width: 600px) {
            display: block;
          }
        }
        .hoursWrapperMobile {
          @media screen and (min-width: 600px) {
            display: none;
          }
        }
        .hoursCtaIcon {
          display: flex;
          align-items: center;
          padding: 0 0 1.25rem 0;
        }
    `}</style>
      {headingStyles}
      {cardBaseStyles}
      {accordionStyles}
      {iconStyles}
    </CardBase>
  );
};

Hours.propTypes = {
  dailyHours: PropTypes.objectOf(
    PropTypes.shape({
      start: PropTypes.string,
      end: PropTypes.string,
      eveningsByAppointment: PropTypes.bool,
      byAppointmentOnly: PropTypes.bool,
      isClosed: PropTypes.bool,
    }),
  ).isRequired,
  hasSchema: PropTypes.bool,
  type: PropTypes.oneOf(['both', 'mobile', 'desktop']),
};

export default Hours;