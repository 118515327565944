/* eslint-disable complexity */
import React from 'react';
import PropTypes from 'prop-types';
import { Link, Accordion, AccordionTab, IconBusiness } from '@lmig/lmds-react';
import CardBase from '@lmig/dotcom-aspect-components/Utility/CardBase';
import css from 'styled-jsx/css';
import useWindowWidth from '@lmig/dotcom-aspect-comparion-hooks/useWindowWidth';
import Heading from '@lmig/dotcom-aspect-components/Utility/Heading';

const { className: headingClass, styles: headingStyles } = css.resolve`
  @import './global-styles/tokens';
  .lm-Heading {
    padding: 0 0 0 1rem;
    margin: 0;
  }
`;

const { className: linkClass, styles: linkStyles } = css.resolve`
  .lm-Link {
    display: block;
    margin-top: 1rem;
    margin-bottom: 0.5rem;
    font-weight: 700;
    text-decoration: none;
  }
  .lm-Link:hover {
    text-decoration: underline;
  }
  *.reservationLink {
    margin-top: 0rem;
  }
`;

const DivOrHeader = ({ isDiv, className, children }) => isDiv
  ? <div className={className}>{children}</div>
  : <>
    <h3 className={className}>{children}</h3>
    <style jsx>{`
      .addressWrapper {
        font-weight: 400;
        margin: 0;
      }
    `}</style>
  </>;

const renderOfficeLocationInfo = ({ office, isAgentRemote, hasSchema, reservationLink, isMobile }) => {
  const itemProps = hasSchema
    ? {
      address: { 'itemProp': 'address', itemScope: 'itemScope', itemType: 'https://schema.org/PostalAddress' },
      addressRegion: { 'itemProp': 'addressRegion' },
      postalCode: { 'itemProp': 'postalCode' },
    }
    : {
      address: {},
      addressRegion: {},
      postalCode: {},
    };

  return (
    <>
      {office.location && JSON.stringify(office.location.coordinates) !== "[0,0]" && hasSchema && (
        <>
          <span className="coordinates" itemProp="geo" itemScope itemType="https://schema.org/GeoCoordinates">
            <meta itemProp="latitude" content={office.location.coordinates[1]} />
            <meta itemProp="longitude" content={office.location.coordinates[0]} />
          </span>
          <span itemScope itemProp="areaServed" itemType="http://schema.org/State">
            <meta itemProp="name" content={office.address.state.name} />
          </span>
        </>
      )}
      {isMobile && <h2 style={{ display: 'none' }}>Location</h2>}
      <DivOrHeader className="addressWrapper" isDiv={!hasSchema}>
        <address className="address" {...itemProps.address}>
          {office.address.street && <div className="line1">{`${office.address.street} `}</div>}
          {office.address.additionalStreetInfo && <div className="line2">{`${office.address.additionalStreetInfo} `}</div>}
          <span className="city">{`${office.address.city}, `}</span>
          <abbr title={office.address.state.name} {...itemProps.addressRegion}>{office.address.state.code}</abbr>
          {office.address.zip && <span className="zipcode" {...itemProps.postalCode}>{` ${office.address.zip}`}</span>}
          {office.address.street && hasSchema &&
            <meta itemProp="streetAddress" content={`${office.address.street} ${office.address.additionalStreetInfo}`} />
          }
        </address>
      </DivOrHeader>
      {!isAgentRemote && office.address && (
        <Link
          className={linkClass}
          variant="standalone"
          data-action="action"
          target="_BLANK"
          rel="noopener noreferrer"
          aria-label="Get Directions"
          href={`https://www.google.com/maps/dir//${office.address.street},${office.address.city},${office.address.state.code},${office.address.zip}`}
        >
          Get directions
        </Link>
      )}
      {reservationLink && (
        <Link
          className={`${linkClass} reservationLink`}
          variant="standalone"
          data-action="action"
          target="_BLANK"
          rel="noopener noreferrer"
          aria-label="Book an appointment"
          href={reservationLink}
        >
          Book an appointment
        </Link>
      )}
      <style jsx>{`
      .addressWrapper {
        font-weight: 400;
        margin: 0;
      }
      .address {
        font-style: normal;
      }
      abbr {
        text-decoration: none;
      }
    `}</style>
    </>
  );
};

const OfficeInfoCard = ({
  office,
  className,
  isAgentRemote,
  hasSchema = true,
  reservationLink,
}) => {
  const { isMobile } = useWindowWidth();

  const { className: iconClass, styles: iconStyles } = css.resolve`
    .lm-Icon {
      min-height: 2rem;
      min-width: 2rem;
    }
  `;

  return (
    <CardBase className={`info ${className}`} shadow >
      {(isMobile !== undefined && isMobile)
        ?
        <Accordion as="div" noBorderTop>
          <AccordionTab as="div" icon={<IconBusiness className={iconClass} />} labelVisual="Location" active >
            {renderOfficeLocationInfo({ office, isAgentRemote, hasSchema, reservationLink, isMobile })}
          </AccordionTab>
        </Accordion>
        :
        <div className="cardInfoWrapper">
          <div className="cardInfoCtaIcon">
            <IconBusiness className={iconClass} />
            <Heading type="h6" tag={hasSchema ? "h2" : "div"} className={headingClass}>Location</Heading>
          </div>
          {renderOfficeLocationInfo({ office, isAgentRemote, hasSchema, reservationLink })}
        </div>
      }
      <style jsx>{`
      .cardInfoWrapper {
        padding: 1rem;
      }
      .cardInfoCtaIcon {
        display: flex;
        align-items: center;
        padding: 0 0 1.25rem 0;
      }
    `}</style>
      {headingStyles}
      {linkStyles}
      {iconStyles}
    </CardBase>
  );
};

OfficeInfoCard.propTypes = {
  office: PropTypes.shape({
    location: PropTypes.shape({
      coordinates: PropTypes.arrayOf(PropTypes.number).isRequired
    }).isRequired,
    address: PropTypes.shape({
      street: PropTypes.string.isRequired,
      additionalStreetInfo: PropTypes.string,
      city: PropTypes.string.isRequired,
      zip: PropTypes.string.isRequired,
      state: PropTypes.shape({
        name: PropTypes.string.isRequired,
        code: PropTypes.string.isRequired
      }).isRequired
    }).isRequired,
  }).isRequired,
  isAgentRemote: PropTypes.bool.isRequired,
  hasSchema: PropTypes.bool,
  reservationLink: PropTypes.string,
};

export default OfficeInfoCard;